import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const WikipediasNakedFacts = () => (
  <Layout>
    <SEO title="Wikipedia's Naked Facts" />
    <h1>Wikipedia's Naked Facts</h1>
      <p>(this probably needs updating but may be of historical interest)</p>
      <p>Wikipedia’s English-language sauna page has a few contradictory statements about clothing in the sauna.</p>
      <p>Of saunas at swimming pools in North America, Western Europe and Russia, it apparently depends on the location of the sauna. “This may be a separate area where swim wear may be taken off or a smaller facility in the swimming pool area where one should keep the swim wear on”.</p>
      <p>If you’re with the same family, mixed gender nudity is we’re told, common. If you’re on a cruise ship, or in a hotel, you’ll probably have to wear a towel around your waist when having a drink by the sauna. Clearly vital information.</p>
      <p>Above all, the consensus on what you should wear depends on where in the world you are and whether you’re in a mixed or same-sex sauna. So an entire section on Wikipedia’s page is devoted to “modern sauna culture around the world”. From this we derive the following table.</p>
      <p><em>Countries are in the order in which they are listed on Wikipedia. A “?” denotes a fact that is not stated on Wikipedia.</em></p>
      <table class="datatable">
          <tbody>
          <tr>
              <th rowspan="2">Country</th>
              <th colspan="2">Etiquette</th>
              <th rowspan="2">Which are most common?</th>
          </tr>
          <tr>
              <th>Single-Sex</th>
              <th>Mixed</th>
          </tr>
          <tr>
              <td>Finland</td>
              <td>Garments rarely worn</td>
              <td>Garments rarely worn</td>
              <td>Single-gender times more common, but mixed bathing does happen.</td>
          </tr>
          <tr>
              <td>Estonia</td>
              <td>?</td>
              <td>?</td>
              <td>Both</td>
          </tr>
          <tr>
              <td>Latvia</td>
              <td>?</td>
              <td>?</td>
              <td>Both</td>
          </tr>
          <tr>
              <td>Lithuania</td>
              <td>?</td>
              <td>?</td>
              <td>Both</td>
          </tr>
          <tr>
              <td>Russia</td>
              <td>?</td>
              <td>&#8211;</td>
              <td>Strictly single-sex.</td>
          </tr>
          <tr>
              <td>Sweden</td>
              <td>?</td>
              <td>?</td>
              <td>Public saunas generally single-sex.</td>
          </tr>
          <tr>
              <td>Germany</td>
              <td>Nude</td>
              <td>Nude</td>
              <td>Single-sex rare, most both women-only and mixed-gender.</td>
          </tr>
          <tr>
              <td>Austria</td>
              <td>Nude</td>
              <td>Nude</td>
              <td>Single-sex rare, most both women-only and mixed-gender.</td>
          </tr>
          <tr>
              <td>Benelux</td>
              <td>Usually Nude</td>
              <td>Usually Nude.</td>
              <td>Single-sex rare, most both women-only and mixed-gender. Some bathing suit days/mornings.</td>
          </tr>
          <tr>
              <td>Switzerland (German-Speaking)</td>
              <td>Nude</td>
              <td>Nude.</td>
              <td>Single-sex rare, most both women-only and mixed-gender.</td>
          </tr>
          <tr>
              <td>Switzerland (French-Speaking)</td>
              <td>Most often Nude.</td>
              <td>Often choice nude/clothed.</td>
              <td>Both common. Some places offer nude single-sex, nude mixed-gender AND clothed mixed-gender.</td>
          </tr>
          <tr>
              <td>France</td>
              <td>Nudity tolerated</td>
              <td>Nudity usually forbidden</td>
              <td>Single-gender most common type.</td>
          </tr>
          <tr>
              <td>UK</td>
              <td>Nudity tolerated</td>
              <td>Nudity usually forbidden</td>
              <td>Single-gender most common type.</td>
          </tr>
          <tr>
              <td>Much Of Southern Europe</td>
              <td>Nudity tolerated</td>
              <td>Nudity usually forbidden</td>
              <td>Single-gender most common type.</td>
          </tr>
          <tr>
              <td>Northeast Italy (Friuli and Trentino-Alto Adige/S¸dtirol)</td>
              <td>Nude</td>
              <td>Choice nude/clothed</td>
              <td>?</td>
          </tr>
          <tr>
              <td>Slovenia</td>
              <td>Nude</td>
              <td>Choice nude/clothed</td>
              <td>?</td>
          </tr>
          <tr>
              <td>Croatia</td>
              <td>Nude</td>
              <td>Choice nude/clothed</td>
              <td>?</td>
          </tr>
          <tr>
              <td>Croatia</td>
              <td>?</td>
              <td>Usually Swimsuits, nude tolerance rare.</td>
              <td>Single-sex rare.</td>
          </tr>
          <tr>
              <td>Portugal</td>
              <td>?</td>
              <td>?</td>
              <td>?</td>
          </tr>
          <tr>
              <td>Hungary</td>
              <td>?</td>
              <td>?</td>
              <td>?</td>
          </tr>
          <tr>
              <td>Central America (southern Mexico, Guatemala)</td>
              <td>?</td>
              <td>?</td>
              <td>People use temazcals indidvidually</td>
          </tr>
          <tr>
              <td>Africa</td>
              <td>?</td>
              <td>?</td>
              <td>? but lower temperature to Europe.</td>
          </tr>
          <tr>
              <td>Korea</td>
              <td>?</td>
              <td>?</td>
              <td>? but saunas are public bathhouses.</td>
          </tr>
          <tr>
              <td>Japan</td>
              <td>&#8211;</td>
              <td>Nude</td>
              <td>“almost always” single-sex “often required by law”</td>
          </tr>
          <tr>
              <td>US (Upper Peninsula of Michigan, Minnesota, Wisconsin, Iowa)</td>
              <td>Choice nude/clothed</td>
              <td>Clothed</td>
              <td>Widespread due to Finnish American population.</td>
          </tr>
          <tr>
              <td>US (Other)</td>
              <td>Choice nude/clothed</td>
              <td>Clothed</td>
              <td>Not widespread. Single-sex uncommon, most saunas in gym/club changing rooms.</td>
          </tr>
          <tr>
              <td>Australia</td>
              <td>Nudity not tolerated</td>
              <td>Nudity not tolerated</td>
              <td>Most often mixed.</td>
          </tr>
          </tbody>
      </table>
      <p>So, does this ring true with you? What’s your experience in the above countries? What’s your experience in other parts of the world?</p>
      <p>Share your thoughts at the usual address.</p>
      <p><b>Vaughan</b> (4 February 2010 at 5:32am)</p>
      <blockquote><p>I believe Korean bathhouses are nude with separate sections for men and women. At least this is the case with the Korean bathhouse I visited in Brisbane, Australia.</p>
          <p>In Scandinavia, public saunas are often in dressing rooms so are single sex. They adhere to the no clothing rule, though, so saunas taken at home with family members and friends are nude and mixed-gender.</p></blockquote>
      <p><b>Gill</b> (9 February 2010 at 2:35pm)</p>
      <blockquote><p>
          Even if most common type was based on data, how is it worked out?</p>
          <p>Say one gym has a sauna in each changing room, and another has a single mixed sauna.</p>
          <p>That’s two single-sex saunas, and one mixed. So you could say single-sex is more common.</p>
          <p>Really it should be based on what you’re more likely to encounter. I’d only see one sauna in either gym, so they’d be equally common.</p>
          <p>I hope the Real Saunas data will let us compare data in various ways.</p></blockquote>
      <p><b>Jiri</b> (1 April 2010 at 3:05pm)</p>
      <blockquote><p>
          Saunas in Czech Republic are usually mixed with women only hours.<br />
          Nudity/towel is common. I havent seen swimming costumes in sauna, but its possible in some facilities I havent seen yet (for example at public swimming pools)</p></blockquote>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default WikipediasNakedFacts
